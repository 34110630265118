import {BranchService} from 'components/service/branch.service';
import {CheckClearingGroup, IncomingCheck} from 'components/service/check.type';
import FeeDefinitionCache from 'components/service/fee-definition.cache';
import {FeeDefinition} from 'components/service/fees/fee.types';
import OperationService from 'components/service/operation.service';
import {BalanceSnapshot, Operation, OperationStatus} from 'components/service/operation.types';
import {UserCache} from 'components/service/users/user.cache';
import {Branch} from 'management/BranchTypes';
import {NgTableParams} from 'ng-table';

import nxModule from 'nxModule';
import {CommandService} from 'shared/utils/command/command.types';
import {HttpService} from 'shared/utils/httpService';
import {PageResult} from 'tools/HttpTypes';
import {User} from 'user/UserTypes';

export interface Config {
  productId: number;
  status: string[];
  passbookOnly: boolean;
  passbookId?: number;
  includePrintedInPassbook: boolean;
  printedOperationIds: number[];
  groups?: unknown;
  dateFrom?: Date;
  dateTo?: Date;
}

export interface OperationDetails {
  registrationUser?: User;
  registrationBranch?: Branch;
  approvalUser?: User;
  status: OperationStatus;
  feeName?: string;
  balanceAfter?: number;
  holdsAfter?: number;
  printedInPassbook?: boolean;
  markedForPrintingInPassbook?: boolean;
}

type OperationWithDetails = OperationDetails & Operation;

export class OperationTableBuilder {
  constructor(
    private readonly http: HttpService,
    private readonly userCache: UserCache,
    private readonly operationService: OperationService,
    private readonly command: CommandService,
    private readonly branchService: BranchService,
    private readonly feeDefinitionsCache: FeeDefinitionCache) {
  }

  buildNgTable(config: Config): NgTableParams<OperationWithDetails> {
    return new NgTableParams({
      count: 15
    }, {
      counts: [],
      paginationMaxBlocks: 8,
      paginationMinBlocks: 3,
      getData: async (params: NgTableParams<OperationWithDetails>): Promise<OperationWithDetails[]> => {
        if (!config.productId) {
          return [];
        }

        const [operations, checks, branches, users, feeDefinitions] = await Promise.all([
          this.operationsPromise(params, config),
          this.checksPromise(config),
          this.branchService.toPromise(),
          this.userCache.withParam(true).toPromise(),
          this.feeDefinitionsCache.toPromise()]);

        params.total(operations.totalCount);

        const result: OperationWithDetails[] = operations.result.map((operation: Operation) => {
          const registrationUser = users.find(u => u.id === operation.registrationUserId);
          const check = checks.find(c => c.id === operation.checkId);
          const feeDefinition = this.findFeeDefinition(operation, feeDefinitions);
          const balanceSnapshot = this.findBalanceSnapshot(operation, config);
          const registrationBranch = branches.find(b => b.id === operation.registrationBranchId);
          const isPrintedInPassbook = (config.printedOperationIds || []).includes(operation.id);
          return {
            ...operation,
            feeName: feeDefinition?.feeName,
            feeApplyOn: feeDefinition?.applyOn,
            balanceAfter: balanceSnapshot?.balance,
            holdsAfter: balanceSnapshot?.holds,
            printedInPassbook: isPrintedInPassbook,
            markedForPrintingInPassbook: !isPrintedInPassbook,
            registrationBranch,
            registrationUser,
            check
          };
        });

        const commandIds = operations.result.map(o => o.commandId).filter(id => id) as number[];
        if (commandIds.length === 0) {
          return result;
        }

        const commandDescriptors = await this.command.readByCriteria({
          commandIds: commandIds,
          page: {
            pageNo: params.page() - 1,
            pageSize: params.count()
          }
        });

        if (commandDescriptors.totalCount === 0) {
          return result;
        }

        const descriptors = commandDescriptors.result;
        return result.map(r => {
          if (!r.commandId) {
            return r;
          }

          const descriptor = descriptors.find(d => d.id === r.commandId);
          if (!descriptor) {
            return r;
          }

          const approvalUser = users.find(u => u.id === descriptor.approvedBy);
          return {
            ...r,
            approvalUser
          };
        });
      }
    });
  }

  private findFeeDefinition(operation: Operation, feeDefinitions: FeeDefinition[]): FeeDefinition | undefined {
    if (operation.operationGroup !== 'APPLY_FEE') {
      return undefined;
    }

    return feeDefinitions.find(d => d.id === operation.feeDefinitionId);
  }

  private findBalanceSnapshot(operation: Operation, config: Config): BalanceSnapshot | undefined {
    if (!operation.balanceSnapshots || operation.balanceSnapshots.length === 0) {
      return undefined;
    }

    const snapshots = operation.balanceSnapshots.filter(b => b.productId === config.productId);
    const afterSnapshot = snapshots.find(s => s.phase === 'AFTER');
    return afterSnapshot ? afterSnapshot : snapshots.find(s => s.phase === 'BEFORE');
  }

  private checksPromise(config: Config): Promise<IncomingCheck[]> {
    return this.http.get<IncomingCheck[]>('/checks/incoming', {
      params: {
        productId: config.productId,
        registeredOnFrom: config.dateFrom,
        registeredOnTo: config.dateTo,
        clearingGroup: [CheckClearingGroup.OUTWARD]
      }
    }).toPromise();
  }

  private operationsPromise(params: NgTableParams<OperationWithDetails>, config: Config): Promise<PageResult<Operation>> {
    return this.operationService.fetchOperationsByCriteria({
      params: {
        pageNo: params.page() - 1,
        pageSize: params.count(),
        productIds: [config.productId],
        fetchAttributes: true,
        fetchBalanceSnapshots: true,
        fetchPassbookOperationsOnly: config.passbookOnly,
        passbookId: config.passbookId,
        nonPrintedPassbookOperationsOnly: config.passbookOnly && !config.includePrintedInPassbook,
        orderByTransactionId: true,
        status: config.status,
        group: config.groups,
        dateFrom: config.dateFrom,
        dateTo: config.dateTo
      }
    });
  }
}

nxModule.factory('operationTableBuilder', OperationTableBuilder);
