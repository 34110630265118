import nxModule from 'nxModule';

import systemPropertyService from '../../../../../react/system/systemPropertyService';
import templateUrl from './update-system-property.template.html';

class UpdateSystemProperty {

  constructor(confirmationTemplate, $filter, $route, command, authentication, userCache) {

    this.confirmationTemplate = confirmationTemplate;
    this.$filter = $filter;
    this.$route = $route;
    this.command = command;
    this.authentication = authentication;
    this.userCache = userCache;

    this.editableCodes = [
      'UI_INFORMATION_BAR_MESSAGE',
      'COMMAND_EXECUTION_ALLOWED',
      'LEDGER_MAX_BACKDATING_DAYS',
      'MAX_CONCURRENT_REPORT_REQUESTS',
      'LOAN_FUTURE_DATE_GRANTED_ALLOWED',
      'LOAN_PAYMENT_VALUE_DATE_ENABLED',
      'AUTOMATED_GROUP_NAME_GENERATION',
      'COLLECTIONS_MODULE_RECEIPT_REQUIRED',
      'RESTRICTED_CUSTOMER_TIME_TO_LIVE',
      'COLLECTION_OFFLINE_FORECAST_DAYS',
      'COCREE_BRANCH_FILTER',
      'FTP_RATE'
    ];

    this.codesRestrictedToTechnicalUser = [
      'MIGRATION_MODE_ENABLED',
      'LEDGER_DISTRIBUTION_MODE'
    ];
  }

  async updateProperty() {
    await this.command.execute('UpdateSystemProperty', {
      entries: [this.input]
    }).toPromise();

    window.location.reload();
  }

  execute() {
    this.confirmationTemplate({
      question: 'Do you want to update the system property?',
      details: [
        {label: 'Property code', description: this.input.code},
        {label: 'Property value', description: this.input.value}
      ],
      yesCallback: () => this.updateProperty()
    });
  }

  async $onInit() {
    this.input = {
      code: 'UI_INFORMATION_BAR_MESSAGE',
      value: ''
    };


    // FIXME: race condition between reading property and setting value in ui
    const uiBarMessage = systemPropertyService.getProperty('UI_INFORMATION_BAR_MESSAGE');
    if (uiBarMessage) {
      this.input.value = uiBarMessage;
    }

    await this.showRestrictedCodesForTechnicalUser();
  }

  async showRestrictedCodesForTechnicalUser() {
    const users = await this.userCache.withParam().toPromise();
    const user = users.find(u => u.id === this.authentication.context.id);
    if (user?.technical) {
      this.editableCodes = [...this.editableCodes, ...this.codesRestrictedToTechnicalUser].sort();
    }
  }

  resetInputValue(code) {
    this.input.value = systemPropertyService.getProperty(code);
  }
}

nxModule.component('updateSystemProperty', {
  templateUrl,
  controller: UpdateSystemProperty
});
